import React, { useState, useEffect } from "react";
import RightArrow from "../assets/Icons/go.svg";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import Layout from "../components/layouts/layout";
import DataService from "../services/api/data_services";
import Button from "../components/Button";
import BlurCardList from "../templates/BlurCardList";
import Blr1 from "../assets/Images/Industry/IndustryHome/Group5993@2x.png";
import Blr2 from "../assets/Images/Industry/IndustryHome/Group5994@2x.png";
import Blr3 from "../assets/Images/Industry/IndustryHome/Group5995@2x.png";
import Blr4 from "../assets/Images/Industry/IndustryHome/Group5996@2x.png";
import Blr5 from "../assets/Images/Industry/IndustryHome/Group5997@2x.png";
import Blr6 from "../assets/Images/Industry/IndustryHome/Group5998@2x.png";
import Blr7 from "../assets/Images/Industry/IndustryHome/Group5999@2x.png";
import Blr8 from "../assets/Images/Industry/IndustryHome/Group6000@2x.png";
import Blr9 from "../assets/Images/Industry/IndustryHome/Group6001@2x.png";
import Blr10 from "../assets/Images/Industry/IndustryHome/Group6002@2x.png";
// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";
import KeyClient from "../assets/Images/WhoWeWorkWith/Group_6104_2x.png";
import KeyEnterPrise from "../assets/Images/WhoWeWorkWith/Group_6106_2x.png";
import Typography from "@material-ui/core/Typography";
import { BASE_SITE_URL } from "../shared/constants";
import BackArrowIcon from "../assets/Images/accordian.svg";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
import BannerBgImage from "../assets/Images/Homepage/who_we_work_with_banner_hover.jpg";

//Info View
import Infoview from "../templates/InfoView";

//Mini Image Banner 1
import MiniImageBannerBgImgWhat from "../assets/Images/Homepage/whatWeDoBanner.png";
import ContactImg from "../assets/Images/Homepage/HeroSection/contact.svg";

//Image slider (Clients)
import ImageSlider from "../templates/ImageSlider";
import SliderImg1 from "../assets/Images/WhoWeWorkWith/clients/cake_by_mehwish_logo.png";
import SliderImg2 from "../assets/Images/WhoWeWorkWith/clients/cedar-medical-suppliers-logo.png";
import SliderImg3 from "../assets/Images/WhoWeWorkWith/clients/crash-vid-logo.png";
import SliderImg4 from "../assets/Images/WhoWeWorkWith/clients/dental-care-logo.png";
import SliderImg5 from "../assets/Images/WhoWeWorkWith/clients/don-logo.png";
import SliderImg6 from "../assets/Images/WhoWeWorkWith/clients/mic-computers-logo.png";
import SliderImg7 from "../assets/Images/WhoWeWorkWith/clients/ooberfit-logo.png";
import SliderImg8 from "../assets/Images/WhoWeWorkWith/clients/plan-que-logo.png";
import SliderImg9 from "../assets/Images/WhoWeWorkWith/clients/reggie-logo.png";
import SliderImg10 from "../assets/Images/WhoWeWorkWith/clients/the-all-things-furniture-company-logo.png";

import BlrMbl1 from "../assets/Images/Industry/Industried_mobile/startup.png";
import BlrMbl2 from "../assets/Images/Industry/Industried_mobile/healthcare.png";
import BlrMbl3 from "../assets/Images/Industry/Industried_mobile/retail_ecommerce.png";
import BlrMbl4 from "../assets/Images/Industry/Industried_mobile/finance.png";
import BlrMbl5 from "../assets/Images/Industry/Industried_mobile/education.png";

//Image slider (enterprise)

import ESliderImg1 from "../assets/Images/WhoWeWorkWith/enterprise/acuity-coaching-logo.png";
import ESliderImg2 from "../assets/Images/WhoWeWorkWith/enterprise/big-blue-hotel-logo.png";
import ESliderImg3 from "../assets/Images/WhoWeWorkWith/enterprise/channel-4-logo.png";
import ESliderImg4 from "../assets/Images/WhoWeWorkWith/enterprise/close-brothers-asset-management-logo.png";
import ESliderImg5 from "../assets/Images/WhoWeWorkWith/enterprise/gnc-live-well-logo.png";
import ESliderImg6 from "../assets/Images/WhoWeWorkWith/enterprise/hinch-property-management-logo.png";
import ESliderImg7 from "../assets/Images/WhoWeWorkWith/enterprise/NHS-Logo.png";
import ESliderImg8 from "../assets/Images/WhoWeWorkWith/enterprise/the-manchester-school-logo.png";
import ESliderImg9 from "../assets/Images/WhoWeWorkWith/enterprise/university-of-manchester-logo.png";
import ESliderImg10 from "../assets/Images/WhoWeWorkWith/enterprise/CERN-logo.png";

//Two Column Contact Info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";

//Image List
import ImageList from "../templates/ImageList";
import ImageListDynamic from "../templates/ImageListDynamic";
import Award1 from "../assets/Images/WhoWeWorkWith/awards/Mobile-App-Developers-2020-award.png";
import Award2 from "../assets/Images/WhoWeWorkWith/awards/clutch-b2b-award.png";
import Award3 from "../assets/Images/WhoWeWorkWith/awards/mobile-app-daily-top-app-development-company-award.png";
import Award4 from "../assets/Images/WhoWeWorkWith/awards/digital-entrepreneur-awards.png";
import Award5 from "../assets/Images/WhoWeWorkWith/awards/clutch-top-100-companies.png";

//Mini view
import Miniview from "../templates/MiniView";

import awardsForMobile from "../assets/Images/About Us/awards____2x.png";
import ogImage from "../assets/Images/ogImages/OG_Who-we-work.png";
import CtaCardSection from "../components/CTASection/ctaCard2";

const WhoWeWorkWith = () => {
  const [awardsPage, setAwardsPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [awardsLimit, setAwardsLimit] = useState(5);
  const [awardRawData, setAwardRawData] = useState([]);
  const [awardsData, setAwardsData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };
  useEffect(() => {
    DataService.getData(
      `admin/api/who-we-work?limit=6&page=${page}&type=Client%20Story&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/awards?limit=${awardsLimit}&page=${awardsPage}&type=Awards`
    ).then((response) => {
      setAwardsData([...awardsData, ...response.data]);
      setAwardRawData(response);
    });
  }, [awardsPage]);
  /**
   * Image Banner
   */
  const ImageBannerData = {
    imageUrl: BannerBgImage,
    bnrImg: BannerBgImage,
    component: "Who",
    bnrImgTitle: (
      <>
        {" "}
        Who We Work <br /> With
      </>
    ),
    // THIS SHOULD BE UNCOMMENTED ONCE ALL INDUSTRY PAGES ARE COMPLETED WITH RESPONSIVENESS AND WITH NECESSARY BACKEND CHANGES
    // bnrImgSubTitle: "Industries",
    servicesColumn1: {
      data: [
        {
          service: "Startups",
          link: "/who-we-work-with/startups",
        },
        {
          service: "Finance",
          link: "/who-we-work-with/finance",
        },
        {
          service: "Hospitality",
          link: "/who-we-work-with/hospitality",
        },
        {
          service: "Not-For-Profit",
          link: "/who-we-work-with/not-for-profit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Healthcare",
          link: "/who-we-work-with/healthcare",
        },
        {
          service: "Education",
          link: "/who-we-work-with/education",
        },
        {
          service: "Real Estate & PropTech",
          link: "/who-we-work-with/real-estate-and-proptech",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Retail and E-commerce",
          link: "/who-we-work-with/retail-and-e-commerce",
        },
        {
          service: "Media and Publishing",
          link: "/who-we-work-with/media-and-publishing",
        },
        {
          service: "Public Sector",
          link: "/who-we-work-with/public-sector",
        },
      ],
    },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="whowoworkexplore ttc">
        <span className="red-3letter-mbl">Exp</span>lore How We Deliver <br />{" "}
        <span className="red-line3letter">Usi</span>ng Digital Superpowers
      </div>
    ),
    tag: "h3",
    width: "95px",
    InfoviewContent: (
      <div className="whowoworkexploreContent">
        Working with Startups, Scaleups and Enterprises in multiple industries,
        we accelerate digital capabilities at scale. We have helped hundreds of
        customers to transform their operations through disruptive technical
        innovation and digital strategies. Find out how we add value, create
        rapid growth, implement future ready technologies and help launch new
        business models, but not limited to following industries:
      </div>
    ),
  };

  const BlurCardListData12 = {
    cardsData: [
      {
        image: Blr1,
        title: "Startup",
        button: "no",
        text: "white",
        cardHgt: "card-hgt",
        route: "/who-we-work-with/startups",
      },
      {
        image: Blr2,
        title: "Healthcare",
        button: "no",
        text: "white",
        cardHgt: "card-hgt",
        route: "/who-we-work-with/healthcare",
      },
      {
        image: Blr3,
        title: (
          <>
            Retail and <br /> E-commerce
          </>
        ),
        button: "no",
        text: "white",
        cardHgt: "card-hgt",
        route: "/who-we-work-with/retail-and-e-commerce",
      },
      {
        image: Blr9,
        title: "Finance",
        button: "no",
        text: "white",
        cardHgt: "card-hgt",
        route: "/who-we-work-with/finance",
      },
      {
        image: Blr8,
        title: "Education",
        button: "no",
        text: "white",
        cardHgt: "card-hgt",
        route: "/who-we-work-with/education",
      },
      // {
      //   image: Blr4,
      //   title: "Media and Publishing",
      //   button: "no",
      //   text: "white",
      //   cardHgt: "card-hgt",
      //   route: "/who-we-work-with/media-and-publishing",
      // },
      // {
      //   image: Blr5,
      //   title: "Hospitality",
      //   button: "no",
      //   text: "white",
      //   cardHgt: "card-hgt",
      //   route: "/who-we-work-with/hospitality",
      // },
      // {
      //   image: Blr10,
      //   title: "Real Estate and PropTech",
      //   button: "no",
      //   text: "white",
      //   cardHgt: "card-hgt",
      //   route: "/who-we-work-with/real-estate-and-proptech",
      // },
      // {
      //   image: Blr7,
      //   title: "Public Sector",
      //   button: "no",
      //   text: "white",
      //   cardHgt: "card-hgt",
      //   route: "/who-we-work-with/public-sector",
      // },
      // {
      //   image: Blr6,
      //   title: "Not-For-Profit",
      //   button: "no",
      //   text: "white",
      //   cardHgt: "card-hgt",
      //   route: "/who-we-work-with/not-for-profit",
      // },
    ],
  };

  /**
   * Image Slider
   */

  const ImageSliderData = {
    ImageClass: "slider-img-short-logos whoweworkclients",
    responsive: {
      0: { items: 2 },
      360: { items: 3 },
      600: { items: 2 },
      700: { items: 3 },
      1024: { items: 4 },
      1360: { items: 5 },
      1600: { items: 6 },
    },
    galleryItems: [
      {
        src: `${SliderImg1}`,
        alt: "MEHWISH",
      },
      {
        src: `${SliderImg2}`,
        alt: "CMS",
      },
      {
        src: `${SliderImg3}`,
        alt: "CrashVID",
      },
      {
        src: `${SliderImg4}`,
        alt: "DENTAL CARE",
      },
      {
        src: `${SliderImg5}`,
        alt: "DON",
      },
      {
        src: `${SliderImg6}`,
        alt: "Mic Computers",
      },
      // {
      //   src: `${SliderImg7}`,
      //   alt: "Ooberfit"
      // },
      {
        src: `${SliderImg8}`,
        alt: "Plan Que",
      },
      {
        src: `${SliderImg9}`,
        alt: "Reggie",
      },
      {
        src: `${SliderImg10}`,
        alt: "The All thing Furniture",
      },
    ],
  };
  const EnterpriseImageSliderData = {
    ImageClass: "slider-img-short-logos whoweworkclients",
    responsive: {
      0: { items: 2 },
      360: { items: 3 },
      600: { items: 2 },
      700: { items: 3 },
      1024: { items: 4 },
      1360: { items: 5 },
      1600: { items: 6 },
    },
    galleryItems: [
      {
        src: `${ESliderImg1}`,
        alt: "acuity-coaching",
      },
      {
        src: `${ESliderImg2}`,
        alt: "big-blue-hotel",
      },
      {
        src: `${ESliderImg3}`,
        alt: "channel-4",
      },
      {
        src: `${ESliderImg4}`,
        alt: "ose-brothers-asset-management",
      },
      {
        src: `${ESliderImg5}`,
        alt: "gnc-live-well",
      },
      {
        src: `${ESliderImg6}`,
        alt: "hinch-property",
      },
      {
        src: `${ESliderImg7}`,
        alt: "NHS",
      },
      {
        src: `${ESliderImg8}`,
        alt: "school",
      },
      {
        src: `${ESliderImg9}`,
        alt: "manchester",
      },
      {
        src: `${ESliderImg10}`,
        alt: "CERN",
      },
    ],
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Imagelist
   */

  const ImagelistData = {
    ImageListTitle: (
      <div className="ttc">
        <span className="red-3letter-mbl">Awa</span>rds &<br />
        <span className="red-line3letter">Rec</span>ognitions
      </div>
    ),
    ImageListSubTitle: (
      <>
        Driven by an endless passion for technology, Digiryte is making progress
        in shaping the app economy. The awards that we have received are a
        testament to this.
      </>
    ),
    ImageListButtonColor: "primary",
    width: "113px",
    ImageListButtonLabel: "Find out More",
    awards: [
      {
        awardImage: Award1,
        awardTitle: "Top Mobile App Developers",
        awardSubTitle: "For 3 consecutive years",
        awardAlt: "awardAlt1",
      },
      {
        awardImage: Award2,
        awardTitle: "Top B2B Companies",
        awardSubTitle: "For 4 consecutive years",
        awardAlt: "awardAlt2",
      },
      {
        awardImage: Award3,
        awardTitle: "Top Cross-platform app development companies",
        awardSubTitle: "For 4 consecutive years",
        awardAlt3: "awardAlt3",
      },
      {
        awardImage: Award4,
        awardTitle: "Digital Entrepreneur Awards",
        awardSubTitle: "For 2 consecutive years",
        awardAlt4: "awardAlt4",
      },
      {
        awardImage: Award5,
        awardTitle: "Clutch Top 100 Companies",
        awardSubTitle: "For 2 consecutive years",
        awardAlt5: "awardAlt5",
      },
    ],
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  // Mobile movie slider
  const mobMovieSlider = {
    imageUrl: KeyClient,
  };
  // Mobile movie slider
  const mobMovieSlider1 = {
    imageUrl: KeyEnterPrise,
  };

  // Mobile movie slider
  const mobMovieSlider2 = {
    imageUrl: awardsForMobile,
  };

  const BlurCardListDataMbl = {
    cardsData: [
      {
        image: BlrMbl1,
        // title: "Startups",
        button: "no",
        text: "white",
        cardHgt: "card-hgt",
        route: "/who-we-work-with/startups",
      },
      {
        image: BlrMbl2,
        // title: "Healthcare",
        button: "no",
        text: "white",
        cardHgt: "card-hgt",
        route: "/who-we-work-with/healthcare",
      },
      {
        image: BlrMbl3,
        // title: (
        //   <>
        //     Retail and <br /> E-commerce
        //   </>
        // ),
        button: "no",
        text: "white",
        cardHgt: "card-hgt",
        route: "/who-we-work-with/retail-and-e-commerce",
      },
      {
        image: BlrMbl4,
        // title: "Finance",
        button: "no",
        text: "white",
        cardHgt: "card-hgt",
        route: "/who-we-work-with/finance",
      },
      {
        image: BlrMbl5,
        // title: "Education",
        button: "no",
        text: "white",
        cardHgt: "card-hgt",
        route: "/who-we-work-with/education",
      },
    ],
  };
  return (
    <Layout
      seo={{
        title: "Who We Work With | Digiryte",
        ogtitle: "Who We Work With | Digiryte",
        description:
          "Digiryte partners with startups, scaleups, and enterprises across various industries to accelerate their digital capabilities and achieve scalable growth.",
        ogimage: ogImage,
        ogurl: `${BASE_SITE_URL}/who-we-work-with`,
        keywords:
          "startups, scaleups, enterprises, digital capabilities, scalable growth, industry partnerships",
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Home"
          routepath="/"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Home
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="mt-50 mbl-hide">
        <BlurCardList
          coponentCount="5"
          componenetName="industry"
          componentId="100"
          BlurCardListData={BlurCardListData12}
        />
      </div>
      <div className="body-container mt-50 hide-in-web">
        <BlurCardList
          // loadMore hiding for now after all industries done wil uncomment this
          componenetName="industry"
          coponentCount="5"
          componentId="300"
          BlurCardListData={BlurCardListDataMbl}
        />
      </div>
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-70">
          <Miniview
            miniviewData={clientStoryData}
            miniviewData2={miniviewDataArticle}
            component="whoWeWorkWith"
          />
          {clientStoryRawData.has_more && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <div
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
                className="mt-60"
                role="presentation"
                onClick={() => setPage(page + 1)}
                style={{ display: "inline-block" }}
              >
                <Button
                  icon={
                    <img
                      className="effect-btn-svgicon"
                      src={!isHover ? RightArrow : RightArrowWhite}
                      alt="Load More"
                    />
                  }
                  customClassName="info-view-resize-btn"
                  color="primary"
                  variant="outlined"
                  label="Load more"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="mt-50">
        <CtaCardSection
          title={
            <>
              <span className="animationTitle">What we do</span>
            </>
          }
          showIcon={false}
          titleClassName="customTitle"
          imageUrl={MiniImageBannerBgImgWhat}
          showBtn
          label="TAKE A LOOK"
          route="/what-we-do"
          imgClassName="custom-cta-img"
          className="common-r-bg"
        />
      </div>
      <div className="mt-70 body-container">
        <h3 className="h3-header">
          {" "}
          <span className="red-line3letter red-3letter-mbl">Key</span> Start-up
          Clients
        </h3>
      </div>
      <div className="mt-50 body-container">
        <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
        <ImageSlider ImageSliderData={ImageSliderData} />
      </div>
      <div className="mt-50 body-container">
        <h3 className="h3-header">
          <span className="red-line3letter red-3letter-mbl">Key</span>{" "}
          Enterprise Clients
        </h3>
      </div>
      <div className="mt-50 body-container">
        <MobileMovieSlider movieSlider={mobMovieSlider1} slideid="slide2" />
        <ImageSlider ImageSliderData={EnterpriseImageSliderData} />
      </div>
      <div className="mt-50">
        <CtaCardSection
          title={
            <>
              We'd love to help with your <br /> product. long-lasting value to{" "}
              <br /> your organisation{" "}
              <span className="animationTitle">Get in touch</span>
            </>
          }
          titleClassName="customTitle"
          imageUrl={ContactImg}
          imgClassName="custom-cta-img"
          className="common-o-bg"
        />
      </div>
      <div className="mt-70"></div>
      <div className="hide-in-mobile">
        {/* <ImageList ImagelistData={ImagelistData} /> */}
        <ImageListDynamic
          ImagelistData={awardsData.length > 0 ? ImagelistData : ""}
          awardsData={awardsData}
        />

        {awardRawData.has_more && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <div
              onMouseEnter={toggleHover}
              onMouseLeave={toggleHover}
              className="mt-60"
              role="presentation"
              onClick={() => {
                setAwardsPage(awardsPage + 1);
                setAwardsLimit(5);
              }}
              style={{ display: "inline-block" }}
            >
              <Button
                icon={
                  <img
                    className="effect-btn-svgicon"
                    src={!isHover ? RightArrow : RightArrowWhite}
                    alt="MORE AWARDS"
                  />
                }
                customClassName="info-view-resize-btn"
                color="primary"
                variant="outlined"
                label="Load more awards"
                // tip="LOAD MORE"
              />
            </div>
          </div>
        )}
        <div className="hide-in-web body-container">
          <h3 className="title-header ">Awards & Recognitions</h3>
        </div>
        <MobileMovieSlider movieSlider={mobMovieSlider2} slideid="slide3" />
      </div>
      <div className="mt-70">
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default WhoWeWorkWith;
